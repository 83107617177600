import http from "@/http";
import eventBus from "@/events";
import moment from "moment";

const TravelProgramming = {
    findAll: async function (ctx) {
        if (ctx) {
            const page = ctx.currentPage - 1 || 0;
            const size = ctx.perPage || 20;
            const sortDesc = ctx.sortDesc ? ',desc' : '';
            const sort = (ctx.sortBy || 'date') + sortDesc;
            const filter = ctx.filter ? ctx.filter : {};
            const pagination = {page, size, sort, sortDesc};
            const params = {...filter, ...pagination};
            const resp = await http("/travelProgrammings/search/all", {params});
            eventBus.$emit('LIST_COUNT_TRAVEL_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.travelProgrammings;
        } else {
            const resp = await http("/travelProgrammings/search/all");
            eventBus.$emit('LIST_COUNT_TRAVEL_EVENT', resp.data.page.totalElements);
            return resp.data._embedded.travelProgrammings;
        }
    },
    create: function (data) {
        return http.post('/travelProgrammings', data);
    },
    update: function (id, data) {
        return http.patch('/travelProgrammings/' + id, data);
    },
    findById(id) {
        return http.get('/travelProgrammings/' + id);
    },
    enable(id) {
        return http.patch('/travelProgrammings/' + id + '/enable')
    },
    disable(id) {
        return http.patch('/travelProgrammings/' + id + '/disable')
    },
    deleteById(id) {
        return http.delete('/travelProgrammings/' + id)
    },
    generateTravels(id) {
        return http.get("travelProgrammings/" + id + "/generatedTravels");
    },
    generateAllTravels() {
        return http.get("travelProgrammings/all/generatedTravels");
    },
    destroyTravels(id) {
        return http.delete("travelProgrammings/" + id + "/deleteTravels");
    },
    closeTravelsTo(date) {
      return http.post("travelProgrammings/close", {}, {params: {date: moment(date).format("DD/MM/YYYY")}})
    },
    findCompany: function (id) {
        return http.get('undefined'.replace('{id}', id));
    }, findDriver: function (id) {
        return http.get('undefined'.replace('{id}', id));
    }, findTurn: function (id) {
        return http.get('undefined'.replace('{id}', id));
    }, findBus: function (id) {
        return http.get('undefined'.replace('{id}', id));
    }, findTravelType: function (id) {
        return http.get('undefined'.replace('{id}', id));
    }, findRoute: function (id) {
        return http.get('undefined'.replace('{id}', id));
    }
};

export default TravelProgramming;
