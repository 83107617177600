<template>
  <div>
    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('ROUTES')})  | capitalize}}</strong>
    </div>
    <b-select
        v-else
        :value="content"
        id="route-input" type="text"
        :state="state"
        @change="handleInput" class="w-100">
      <b-select-option :value="undefined">{{
          $t(undefinedMeans, {
            gender: 'o',
            catalog: $t('TURNS')
          }) | capitalize
        }}
      </b-select-option>
      <b-select-option :key="item.id" v-for="item in items" :value="valueType === 'id' ? item.id : item._links.self.href">{{$t(item.name)}}</b-select-option>
    </b-select>
    <b-form-invalid-feedback id="route-input-feedback">
      <div v-for="error in errors" :key="error.error">
        {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ROUTE')},})  | capitalize }}
      </div>
    </b-form-invalid-feedback>
  </div>

</template>

<script>
import {VModel, SendsMessages} from "@/mixins";
import Route from "@/routes/index";
import eventBus from "@/events";

export default {
  name: "RouteSingleSelector",
  props: ['state', 'errors', 'companyId', 'valueType', 'undefinedMeans', 'turnId'],
  mixins: [VModel, SendsMessages],
  methods: {
    handleInput(val) {
      this.$emit('input', val);
      const test = this.items.find(item => item._links.self.href === val);
      this.$emit('instance', test);
    },
    async loadRoutes(companyId, turnId) {
      try {
        this.loading = true;
        this.items = await Route.findAll({perPage: 1000, filter: {status: true, companyId, turnId}});
        console.log(this.items);
      } catch (e) {
        this.sendError('ERROR_LOADING_CATALOG', {}, e);
      } finally {
        this.loading = false;
      }
    }
  },
  async mounted() {
    await this.loadRoutes();
    const that = this;
    eventBus.$on('TRAVEL_FORM_TURN_CHANGE', async function(params) {
      that.loadRoutes(params.companyId, params.turnId);
    });
  },
  data() {
    return {
      loading: false,
      items: []
    }
  }
}
</script>

<style scoped>

</style>
