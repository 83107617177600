<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('TRAVEL')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <div class="w-100">
            <!-- driver -->
            <b-form-group
                class="w-25 d-inline-block pr-1"
                :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('DRIVER')}) | capitalize"
                label-for="driver-input"
                :state="state('driver')">
              <driver-single-selector
                  id="driver-input"
                  :state="state('driver')"
                  :errors="errors('driver')"
                  undefined-means="FORMS_SELECT_ONE"
                  v-model="$v.form.driver.$model">
              </driver-single-selector>
            </b-form-group>
            <!-- driver -->

            <!-- bus -->
            <b-form-group
                class="w-25 d-inline-block pr-1"
                :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('BUS')}) | capitalize"
                label-for="bus-input"
                :state="state('bus')">
              <bus-single-selector
                  id="bus-input"
                  undefined-means="FORMS_SELECT_ONE"
                  :state="state('bus')"
                  :errors="errors('bus')"
                  v-model="$v.form.bus.$model">
              </bus-single-selector>
            </b-form-group>
            <!-- bus -->

            <!-- from input -->
            <b-form-group
                id="from-form-group"
                class="w-25 d-inline-block"
                :label="$t('TRAVEL_FROM_LABEL') | capitalize"
                label-for="from-input"
                :state="state('from')">
              <b-form-datepicker
                  v-model="$v.form.from.$model"
                  locale="es-MX"
                  :state="state('from')"
                  button-variant="dark"></b-form-datepicker>

              <b-form-invalid-feedback id="from-input-feedback">
                <div v-for="error in errors('from')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TRAVEL_FROM_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- from input -->

            <!-- to input -->
            <b-form-group
                id="to-form-group"
                class="w-25 d-inline-block"
                :label="$t('TRAVEL_TO_LABEL') | capitalize"
                label-for="to-input"
                :state="state('to')">
              <b-datepicker class="w-100 ml-1" id="to-input" type="text"
                            v-model="$v.form.to.$model"
                            :min="form.from ? form.from : new Date()"
                            :state="state('to')"
                            trim></b-datepicker>

              <b-form-invalid-feedback id="to-input-feedback">
                <div v-for="error in errors('to')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TRAVEL_TO_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- to input -->
          </div>

          <div class="w-100">
            <!-- saturday text input -->
            <b-form-group
                class="d-inline-block w-25"
                id="saturday-form-group"
                :label="$t('TRAVEL_SATURDAY_LABEL') | capitalize"
                label-for="saturday-input"
                :state="state('saturday')">
              <b-checkbox class="w-100 ml-1" id="saturday-input" type="text"
                          v-model="$v.form.saturday.$model"
                          :state="state('saturday')"
                          trim></b-checkbox>

              <b-form-invalid-feedback id="saturday-input-feedback">
                <div v-for="error in errors('saturday')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TRAVEL_SATURDAY_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- saturday text input -->

            <!-- sunday text input -->
            <b-form-group
                id="sunday-form-group"
                class="d-inline-block w-25"
                :label="$t('TRAVEL_SUNDAY_LABEL') | capitalize"
                label-for="sunday-input"
                :state="state('sunday')">
              <b-checkbox class="w-100 ml-1" id="sunday-input" type="text"
                          v-model="$v.form.sunday.$model"
                          :state="state('sunday')"
                          trim></b-checkbox>

              <b-form-invalid-feedback id="sunday-input-feedback">
                <div v-for="error in errors('sunday')" :key="error.error">
                  {{
                    $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TRAVEL_SUNDAY_LABEL')},})  | capitalize
                  }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
            <!-- sunday text input -->
          </div>

          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>

    <b-card text-variant="light" bg-variant="dark" class="my-2">
      <b-card-text>
        <div class="w-100">
          <div class="w-75 d-inline-block">
            <h3>{{ $t('TRAVEL_DETAILS_TITLE') | capitalize }}</h3>
          </div>
          <div class="w-25 d-inline-block text-right">
            <b-button variant="primary" @click="showDetailForm">
              <b-icon-plus-circle></b-icon-plus-circle>
            </b-button>
          </div>
        </div>
        <table class="w-100">
          <thead>
          <tr>
            <th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_COMPANY') | capitalize }}</th>
            <th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_TURN') | capitalize }}</th>
            <th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_TRAVEL_TYPE') | capitalize }}</th>
            <th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_ROUTE') | capitalize }}</th>
            <th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_BOTH') | capitalize }}</th>
            <th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_TRAVEL_DIRECTION') | capitalize }}</th>
            <th>{{ $t('TRAVEL_PROGRAMMING_DETAIL_ACCOUNT') | capitalize }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr :key="index" v-for="(detail, index) in form.details">
            <td>{{detail._company}}</td>
            <td>{{detail._turn}}</td>
            <td>{{detail._travelType}}</td>
            <td>{{detail._route}}</td>
            <td class="text-center">
              <b-icon-check-circle v-if="detail.both" class="text-success"></b-icon-check-circle>
              <b-icon-x-circle v-else class="text-danger"></b-icon-x-circle>
            </td>
            <td>{{$t(detail.travelDirection) | capitalize}}</td>
            <td class="text-center">
              <b-icon-check-circle v-if="detail.accountDayBefore" class="text-success"></b-icon-check-circle>
              <b-icon-x-circle v-else class="text-danger"></b-icon-x-circle>
            </td>
            <td>
              <b-button variant="danger" @click="removeDetail(index)">
                <b-icon-x-circle></b-icon-x-circle>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
        <b-modal size="xl" hide-footer id="detail-modal">
          <travel-programming-detail-form @saved="addDetail"></travel-programming-detail-form>
        </b-modal>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {minLength, required, requiredIf} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import TravelProgramming from "@/travel-programmings";
import Constants from "@/constants";
import DriverSingleSelector from "@/drivers/DriverSingleSelector";
import BusSingleSelector from "@/buses/BusSingleSelector";
import TravelProgrammingDetailForm from "@/travel-programmings/TravelProgrammingDetailForm";

export default {
  name: "TravelProgrammingForm",
  components: {
    TravelProgrammingDetailForm,
    DriverSingleSelector,
    BusSingleSelector,
  },
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    async doSave(id, data) {
      let resp = null;
      if(id) {
        resp = await TravelProgramming.update(id, data)
      } else {
        resp = await TravelProgramming.create(data);
      }
      if(id) {
        await TravelProgramming.generateTravels(id);
      } else {
        const split = resp.data._links.self.href.split('/');
        await TravelProgramming.generateTravels(split[split.length - 1]);
      }
      return resp;
    },
    showDetailForm() {
      this.$bvModal.show('detail-modal');
    },
    addDetail(detail) {
      this.form.details.push(detail);
      this.$bvModal.hide('detail-modal');
    },
    removeDetail(idx) {
      this.form.details.splice(idx, 1);
    },
    getData() {
      this.loadingFormData = true;
      TravelProgramming.findById(this.id)
          .then(resp => {
            this.form.from = resp.data.from;
            this.form.to = resp.data.to;
            this.form.saturday = resp.data.saturday;
            this.form.sunday = resp.data.sunday;
            this.form.driver = resp.data._embedded.driver ? resp.data._embedded.driver._links.self.href.replace('{?projection}', '') : undefined;
            this.form.bus = resp.data._embedded.bus ? resp.data._embedded.bus._links.self.href.replace('{?projection}', '') : undefined;
            this.form.details = resp.data.details.map(x => {
              return {
                accountDayBefore: x.accountDayBefore,
                turn: x._embedded.turn._links.self.href,
                company: x._embedded.company._links.self.href,
                travelType: x._embedded.travelType._links.self.href,
                route: x._embedded.route._links.self.href,
                _turn: x._embedded.turn.name,
                _company: x._embedded.company.name,
                _travelType: x._embedded.travelType.name,
                _route: x._embedded.route.name,
                both: x.both,
                travelDirection: x.travelDirection
              }
            });
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  computed: {
    companyId() {
      if (this.form.company) {
        const split = this.form.company.split('/');
        return split[split.length - 1];
      } else {
        return undefined;
      }
    }
  },
  data() {
    return {
      loadingFormData: false,
      originals: {},
      form: {
        from: null,
        to: null,
        saturday: null,
        sunday: null,
        driver: undefined,
        bus: null,
        details: [],
      },
      editPage: 'TRAVEL_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      from: {
        required: requiredIf(function (form) {
          return form.to
        }),
      },
      to: {
        required: requiredIf(function (form) {
          return form.from
        }), minValue: new Date()
      },
      saturday: {},
      sunday: {},
      driver: {required},
      bus: {required},
      details: {
        required, minLength: minLength(1), $each: {}
      },
    }
  }
}
</script>

<style scoped>

</style>
