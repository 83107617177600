<template>
  <b-form @submit.prevent="save">
    <div class="w-100">
      <!-- company -->
      <b-form-group
          class="w-25 d-inline-block"
          :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('COMPANY')}) | capitalize"
          label-for="company-input"
          :state="state('company')">
        <company-single-selector
            class="pr-1"
            id="company-input"
            undefined-means="FORMS_SELECT_ONE"
            @instance="setCompany"
            @input="loadCatalogs"
            :state="state('company')"
            :errors="errors('company')"
            v-model="$v.form.company.$model">
        </company-single-selector>
      </b-form-group>
      <!-- company -->

      <!-- turn -->
      <b-form-group
          class="w-25 d-inline-block"
          :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('TURN')}) | capitalize"
          label-for="turn-input"
          :state="state('turn')">
        <turn-single-selector
            class="pr-1"
            id="turn-input"
            v-if="companyId"
            @instance="setTurn"
            @input="loadRoutes"
            undefined-means="FORMS_SELECT_ONE"
            :state="state('turn')"
            :errors="errors('turn')"
            v-model="$v.form.turn.$model">
        </turn-single-selector>
        <b-select class="w-100" v-else></b-select>
      </b-form-group>
      <!-- turn -->

      <!-- travelType -->
      <b-form-group
          class="w-25 d-inline-block"
          :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('TRAVEL_TYPE')}) | capitalize"
          label-for="travel-type-input"
          :state="state('travelType')">
        <travel-type-single-selector
            class="px-1"
            id="travel-type-input"
            @instance="setTravelType"
            :state="state('travelType')"
            :errors="errors('travelType')"
            v-model="$v.form.travelType.$model">
        </travel-type-single-selector>
      </b-form-group>
      <!-- travelType -->

      <!-- route -->
      <b-form-group
          class="w-25 d-inline-block"
          :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('ROUTE')}) | capitalize"
          label-for="route-input"
          :state="state('route')">
        <route-single-selector
            id="route-input"
            class="pr-1"
            v-if="companyId"
            :turn-id="turnId"
            @instance="setRoute"
            :state="state('route')"
            :errors="errors('route')"
            undefined-means="FORMS_SELECT_ONE"
            v-model="$v.form.route.$model">
        </route-single-selector>
        <b-select class="w-100" v-else></b-select>
      </b-form-group>
      <!-- route -->
    </div>

    <!-- both text input -->
    <b-form-group
        class="w-25 d-inline-block"
        id="account-day-before-form-group"
        :label="$t('TRAVEL_BOTH_LABEL') | capitalize"
        label-for="both-input"
        :state="state('both')">
      <b-checkbox class="w-100 ml-1" id="has-exit-input" type="text"
                  v-model="$v.form.both.$model"
                  :state="state('both')"
                  trim></b-checkbox>

      <b-form-invalid-feedback id="has-exit-input-feedback">
        <div v-for="error in errors('both')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TRAVEL_BOTH_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- both text input -->

    <!-- accountDayBefore text input -->
    <b-form-group
        class="w-25 d-inline-block"
        id="account-day-before-form-group"
        :label="$t('TRAVEL_ACCOUNT_DAY_BEFORE_LABEL') | capitalize"
        label-for="accountDayBefore-input"
        :state="state('accountDayBefore')">
      <b-checkbox class="w-100 ml-1" id="account-day-before-input" type="text"
                  v-model="$v.form.accountDayBefore.$model"
                  :state="state('accountDayBefore')"
                  trim></b-checkbox>

      <b-form-invalid-feedback id="account-day-before-input-feedback">
        <div v-for="error in errors('accountDayBefore')" :key="error.error">
          {{
            $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TRAVEL_ACCOUNT_DAY_BEFORE_LABEL')},})  | capitalize
          }}
        </div>
      </b-form-invalid-feedback>
    </b-form-group>
    <!-- accountDayBefore text input -->

    <div class="w-100">
      <!-- travelDirection -->
      <b-form-group
          v-if="!form.both"
          class="w-25 d-inline-block"
          id="travel-direction-form-group"
          :label="$t('TRAVEL_DIRECTION_LABEL') | capitalize"
          label-for="travel-direction-input"
          :state="state('travelDirection')">
        <b-select v-model="$v.form.travelDirection.$model">
          <b-select-option value="TRAVEL_TO">{{ $t('TRAVEL_PROGRAMMING_TRAVEL_TO') | capitalize }}</b-select-option>
          <b-select-option value="TRAVEL_FROM">{{ $t('TRAVEL_PROGRAMMING_TRAVEL_FROM')  | capitalize }}
          </b-select-option>
        </b-select>

        <b-form-invalid-feedback id="travel-direction-input-feedback">
          <div v-for="error in errors('travelDirection')" :key="error.error">
            {{
              $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TRAVEL_DIRECTION_LABEL')},})  | capitalize
            }}
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      <!-- travelDirection -->
    </div>

    <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
      <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
      <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
      <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
    </b-button>
  </b-form>
</template>

<script>
import {Form} from "@/mixins";
import TravelTypeSingleSelector from "@/travel-types/TravelTypeSingleSelector";
import CompanySingleSelector from "@/companies/CompanySingleSelector";
import TurnSingleSelector from "@/turns/TurnSingleSelector";
import eventBus from "@/events";
import {required} from "vuelidate/lib/validators";
import RouteSingleSelector from "@/routes/RouteSingleSelector";

export default {
  name: "TravelProgrammingDetailForm",
  components: {TravelTypeSingleSelector, RouteSingleSelector, CompanySingleSelector, TurnSingleSelector},
  mixins: [Form],
  methods: {
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }
      console.log(this.form);
      this.$emit('saved', this.form);
    },
    loadCatalogs() {
      eventBus.$emit('TRAVEL_FORM_COMPANY_CHANGE', this.companyId);
    },
    loadRoutes() {
      eventBus.$emit('TRAVEL_FORM_TURN_CHANGE', {turnId: this.turnId, companyId: this.companyId});
    },
    setCompany(company) {
      this.form._company = company.name;
    },
    setTurn(turn) {
      this.form._turn = turn.name;
    },
    setTravelType(travelType) {
      this.form._travelType = travelType.name;
    },
    setRoute(route) {
      this.form._route = route.name;
    },
  },
  computed: {
    companyId() {
      if (this.form.company) {
        const split = this.form.company.split('/');
        return split[split.length - 1];
      } else {
        return undefined;
      }
    },
    turnId() {
      if (this.form.turn) {
        const split = this.form.turn.split('/');
        return split[split.length - 1];
      } else {
        return undefined;
      }
    }
  },
  data() {
    return {
      form: {
        accountDayBefore: false,
        turn: null,
        company: null,
        travelType: null,
        route: null,
        both: true,
        travelDirection: 'TRAVEL_TO'
      }
    }
  },
  validations: {
    form: {
      accountDayBefore: {},
      turn: {required},
      company: {required},
      travelType: {required},
      route: {required},
      both: {},
      travelDirection: {required}
    }
  },
}
</script>

<style scoped>

</style>